div.dropdown-menu {
  max-height: 20.5rem;
  overflow-y: scroll;
}

td.numcell, th.numcell {
  text-align: right;
}

div.chart svg {
    max-width: 400px;
    height: 400px;
    margin: 0 auto;
}

div.chart-wide svg {
    max-width: 600px;
    height: 400px;
    margin: 0 auto;
}

h1, h2, h3, h4, h5, h6 { padding-top: 8px; }

.btn {
  text-transform: none;
}

.btn-link {
  padding-top: 0;
  padding-bottom: 0;
}

nav.navbar.bg-primary {
  background-color: rgb(0, 128, 75) !important;
}

.sub-nav {
  background-color: rgb(179, 255, 223) !important;
}


.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: rgb(0, 166, 97) !important;
}
.perc-blue {
  background-color: #0070c0
}

.perc-green {
  background-color: #70ad47
}

.perc-orange {
  background-color: #ff7f00
}

.perc-red {
  background-color: #ff0000
}

.perc-darkred {
  background-color: #c00000
}

.perc {
  font-weight: bold;
  color: white;
}

.perc-pill {
  font-size: 100% !important;
}

table.stats th {
  font-size: 0.85rem;
}

table.stats td {
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}
@media screen {
  .container-fluid {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}

@media print {
  @page { size: auto }
  body {
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
    font-size: 80%
  }

  a {
    text-decoration: none !important;
    color: inherit;
  }

  div.sub-nav { display: none; }

  abbr[title] { border-bottom: none !important; }

  h1, h2, h3, h4 { page-break-after: avoid; }
  .dont-break { page-break-inside: avoid;}
  .break-before { page-break-before: always }

  table.table tfoot { display: table-row-group }
}